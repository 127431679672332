import { AuthService } from 'src/app/authentication/authentication-guard/auth.service';


import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs/Observable";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private authService:AuthService) {
    }
   
     authReq:any;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
       
    const user= JSON.parse(localStorage.getItem("user"));
    if(user==null){
        this.authService.logout();
    }


    const modifiedReq  = this.addAuthenticationToken(req,user);
 
  
        return next.handle(modifiedReq);

        // return next.handle(req).pipe(
        //     catchError(err => {
        //       console.log(err);

        //     })





    }

    private addAuthenticationToken(req: HttpRequest<any>,user): HttpRequest<any> {
        // If we do not have a token yet then we should not set the header.
        // Here we could first retrieve the token from where we store it.

     
        // const user= JSON.parse(localStorage.getItem("user"));
    
       
        // if (!user['token']) {
        //   return request;
        // }
        // // // If you are calling an outside domain then do not add the token.
        // if (!request.url.match(/auth\//)) {
        //   return request;
        // }



        if(user['roles'][0]==="admin"){
            let result = new HttpHeaders();
            result = result.set('Content-Type', 'application/json');
            result = result.set('Authorization', 'Bearer ' + user['token']);
            result = result.set('clientid', user['id']);
            this.authReq = req.clone({
                headers:result
            })


        }else{
            
                let result = new HttpHeaders();
                result = result.set('Content-Type', 'application/json');
                result = result.set('Authorization', 'Bearer ' + user['token']);
                result = result.set('clientid', user['userId']);
                
              
              
                this.authReq = req.clone({
                    headers:result
                })
        }

       

       
      

        // return request
        // const authReq = req.clone({
          
        //     headers: req.headers.set('Authorization', "Bearer " + user['token'])
        //   });


        //   const authReq = req.clone({
        //     headers: new HttpHeaders({
        //       'Content-Type':  'application/json',
        //       'Authorization': "Bearer " + user['token']
        //     })
        // })
          return this.authReq
      }


}