
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

Injectable()
export class Globals {
   constructor() { }
    public api_Url = "http://103.13.112.91:9092/";
    // public api_Url = "http://localhost:9092/";
    // public api_Url = "";

    
    public ioclDealer = 'api/iocl-agencies';
    public vehicle = 'api/vehicle';
    public vendor = 'api/vendor';
    public employees = 'api/employees';

    public expense = 'api/expense';
    public expense_report = 'api/expense';
    public vendor_payment = 'api/vendor-payment';
    public maintenace_bill = 'api/maintance-bill';
    public maintenanse_perticular = 'api/particluar';
    // public maintenace_bill_report = 'maintenace_bill_report';
    public hpcltrip = 'api/hpcltrip';
    public iocltrip = 'api/iocl-trip';
    public hpagencies = 'api/hpagencies';
    public hpclPayment = 'api/hpclPayment';
    public hpclopening = 'api/hpcl-opening';
    public ioclopening = 'api/iocl-opening-balance';
    public diesel_bills_hpcl = 'api/diesel_bills_hpcl';
    public diesel_bills_iocl = 'api/iocl-dieselbill';
    public ioclPayment_report = 'api/iocl-payment/cheque';
    public hpcltrip_report = 'api/hpcltrip/hpcl-trip-report';
    public iocltrip_report = 'api/iocl-trip/iocl-trip-report';
    public challansInInvoice = 'api/challan/challan-in-invoice';
    public finance_year = 'api/finance-year';
    public finance_year_active = 'api/finance-year/is-active';
    public client = 'api/client';
    public ioclPayment = 'api/iocl-payment' ;
    public challan = 'api/challan';
    public challan_report = 'api/challan/challan';
    public invoice_report = 'api/invoice/invoice-search';


    public invoice = 'api/invoice';
    // public invoice_dues = 'invoice';

    public client_payment = 'api/client-payment';
    public client_dues_by_id = 'api/client-payment/client-dues-clientid';

    public payment_report = 'api/client-payment';
    public diesel_report = 'api/diesel_bills_hpcl/dieselbill-report';
    public extra_diesel = 'api/extra-diesel';
    public extra_diesel_search = 'api/extra-diesel/search';

    public iocl_diesel_report = 'api/iocl-dieselbill/dieselbill-report';

    public hpclPayment_report = 'api/hpclPayment/cheque';



public HPCL_Full_Report='api/hpcl-report/full';
public HPCL_One_Page_Report_Pdf='api/hpcl-report/one-page-pdf';
public HPCL_Vehicle_Report_Pdf='api/hpcl-report/vehicle-trip-pdf';
public HPCL_Trip_Report='api/hpcl-report/trip';
public HPCL_Trip_Report_Pdf='api/hpcl-report/trip-pdf';


public IOCL_Full_Report='api/iocl-report/full';
public IOCL_One_Page_Report_Pdf='api/iocl-report/one-page-pdf';
public IOCL_Vehicle_Report_Pdf='api/iocl-report/vehicle-trip-pdf';
public IOCL_Trip_Report='api/iocl-report/trip';
public IOCL_Trip_Report_Pdf='api/iocl-report/trip-pdf';


public LOCAL_Full_Report='api/local-report/full';
public LOCAL_One_Page_Report_Pdf='api/local-report/one-page-pdf';
public LOCAL_Vehicle_Report_Pdf='api/local-report/trip-pdf';



public Maintenance_Report='api/maintenance-report/maintenance-report';
public Maintenance_Report_Pdf='api/maintenance-report/maintenance-report-pdf';



public User_login='auth/user/login';
public User_add='auth/user/add';












    public getHTTPHeaders(): HttpHeaders {
        let result = new HttpHeaders();
        result = result.set('Content-Type', 'application/json');
        result = result.set('Access-Control-Allow-Headers', 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With,observe"');
        result = result.set('Access-Control-Allow-Methods', 'POST, PUT, GET, OPTIONS, DELETE');
        result = result.set('Access-Control-Allow-Origin', '*');
        // result = result.set('Authorization', accessToken);
        // result = result.set('sessionid', sessionId);
        return result;
      }
}

