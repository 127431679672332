import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
    private spinner: NgxSpinnerService
    ) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // this.spinner.show();
    // setTimeout(() => {
    //   this.spinner.hide();
    // }, 2000);
 
    // console.log(localStorage.getItem('yearid'));
    // console.log(localStorage.getItem('opening_date'));
    // console.log(localStorage.getItem('closing_date'));


  }
}
