
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './authentication/authentication-guard/auth.guard';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
// import { DashAnalyticsComponent } from './demo/dashboard/dash-analytics/dash-analytics.component'
const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/signin',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: 'dashboard',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./demo/dashboard/dashboard.module').then(module => module.DashboardModule)
  },

  {
    path: 'hpcl',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./hpcl/hpcl.module').then(module => module.HpclModule)
  },
  {
    path: 'iocl',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./iocl/iocl.module').then(module => module.IoclModule)
  },
  {
    path: 'local',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./local/local.module').then(module => module.LocalModule)
  },
  {
    path: 'maintenance',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./maintenance/maintenance.module').then(module => module.MaintenanceModule)
  },
  {
    path: 'all-reports',
    component: AdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () => import('./reports/reports.module').then(module => module.ReportsModule)
  },
  { path: '**', redirectTo: 'error/404' },

];











@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
