import { ResponseModel } from './../../common/response-model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { YearModel } from 'src/app/theme/layout/admin/navigation/nav-content/year-model';
import { Globals } from 'src/app/common/global';

@Injectable({
  providedIn: 'root'
})
export class YearsService {

  
  yearmodel=YearModel;
  // private apiServer = "http://192.168.0.19:9090/finance_year";
  // httpOptions={
  //   Headers:new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   })
  // }

  constructor(private httpClient: HttpClient, private global : Globals) { }

  
  getAll()  {
    return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.finance_year )
    // .pipe(
    //   catchError(this.errorHandler)
    //)
  }

  getbyid(id:any)  {
      return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.finance_year + '/'+id)
      // .pipe(
      //   catchError(this.errorHandler)
      //)
    }
    getActiveYear()  {
      return this.httpClient.get<ResponseModel>(this.global.api_Url + this.global.finance_year_active )
      // .pipe(
      //   catchError(this.errorHandler)
      //)
    }

  errorHandler(error:any) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }
}
