import {Injectable} from '@angular/core';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

let obj=
  {
  id: 'year_id',
  title: '2021-2022',
  type: 'item',
  url: '/',
  external: true
}
;

const NavigationItems = [
  {
    id: 'navigation',
    title: 'Navigation',
    type: 'group',
    icon: 'feather icon-monitor',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'item',
        url: '/dashboard/analytics',
        icon: 'feather icon-home'
      },
      {
        id: 'page-hpcl',
        title: 'HPCL',
        type: 'collapse',
        icon: 'feather icon-layout',
        children: [
          {
                id: 'Show-All-Trips',
                title: 'Show All Trips',
                type: 'item',
                url: '/hpcl/hpcl-trip/hpcl-all-trip'
          },
          {
            id: 'Show-Diesel-Bills',
            title: 'Show Diesel List',
            type: 'item',
            url: '/hpcl/hpcl-trip/dieselbills'
          },
          {
            id: 'HPCL-Dealer',
            title: 'HPCL-Dealer',
            type: 'item',
            url: '/hpcl/dealer/all-dealers'
          },
          {
            id: 'HPCL-Cheque',
            title: 'HPCL-Cheque',
            type: 'item',
            url: '/hpcl/hpcl-cheque/all-cheques'
          },
        ]
      },
      {
        id: 'IOCL-1',
        title: 'IOCL',
        type: 'collapse',
        icon: 'feather icon-server',
        children: [
          {
                id: 'Show-All-Trips',
                title: 'Show All Trips',
                type: 'item',
                url: '/iocl/iocl-trip/all-trips'
          },
          {
            id: 'Show-Diesel-Bills',
            title: 'Show Diesel Bills',
            type: 'item',
            url: '/iocl/iocl-trip/iocl-diesel-list'
          },
          {
            id: 'IOCL-Dealer',
            title: 'IOCL-Dealer',
            type: 'item',
            url: '/iocl/iocl-dealer/iocl-all-dealers'
          },
          {
            id: 'IOCL-Cheque',
            title: 'IOCL-Cheque',
            type: 'item',
            url: '/iocl/iocl-cheque/iocl-all-cheques'
            // children: [
            //   {
            //     id: 'New-Cheque',
            //     title: 'New Cheque',
            //     type: 'item',
            //     url: '/iocl/iocl-cheque/iocl-new-cheque'
            //   },
            //   {
            //     id: 'Show-All-Cheques',
            //     title: 'Show All Cheques',
            //     type: 'item',
                
            //   },
            //   {
            //     id: 'Opening-Balance',
            //     title: 'Opening Balance',
            //     type: 'item',
            //     url: '/iocl/iocl-cheque/iocl-opening-balance'
            //   }
            // ]
          },
        ]
      },
      {
        id: 'local',
        title: 'Local',
        type: 'collapse',
        icon: 'feather icon-sidebar',
        children: [
          {
            id: 'Client',
            title: 'Client',
            type: 'item',
            url: '/local/local-client/all-local-client'
          },
          {
            id: 'Challan',
            title: 'Challan',
            type: 'item',
            url: '/local/local-challan/all-challans'
          },
          {
            id: 'Invoice',
            title: 'Invoice',
            type: 'item',
            url: '/local/local-invoice/local-all-invoices'
          },
          {
            id: 'Payment',
            title: 'Payment',
            type: 'item',
            url: '/local/local-payment/client-dues'

            // children: [
            //   {
            //     id: 'New-Payment',
            //     title: 'New Payment',
            //     type: 'item',
            //     url: '/local/local-payment/new-payment'
            //   },
            //   {
            //     id: 'Show-All-Payment',
            //     title: 'Show All Payment',
            //     type: 'item',
            //   },
            //   {
            //     id: 'Client-Dues',
            //     title: 'Client Dues',
            //     type: 'item',
            //     url: '/local/local-payment/client-dues'
            //   }
            // ]
          },
        ]
      },
      {
        id: 'Maintenance',
        title: 'MAINTENANCE',
        type: 'collapse',
        icon: 'feather icon-sliders',
        children: [
            
              {
                id: 'All-Vendors',
                title: 'All Vendors',
                type: 'item',
                url: '/maintenance/maintenance-vendor/all-vendors'
              },
              {
                id: 'All-Employees',
                title: 'All Employees',
                type: 'item',
                url: '/maintenance/maintenance-employee/all-employees'
              },
              {
                id: 'All-Maintenance-Bills',
                title: 'All Maintenance Bills',
                type: 'item',
                url: '/maintenance/maintenance-vendor/all-maintenance-bill'
              },
              {
                id: 'Vendors-Payments',
                title: 'Vendors Payments',
                type: 'item',
                url: '/maintenance/maintenance-vendor/vendor-dues'
              }
            
          ,
          {
            
              
                id: 'All-Vehicle',
                title: 'All Vehicle',
                type: 'item',
                url: '/maintenance/vehicle/all-vehicle'
              }
              // ,
              // {
              //   id: 'Vehicle-Expese',
              //   title: 'All Vehicle Expense',
              //   type: 'item',
              //   url: '/maintenance/vehicle/all-vehicle-expenses'
              // }
            
          // ,
          // {
          //   id: '/maintenance/extra-diesel/show-diesel',
          //   title: 'Extra-Diesel',
          //   type: 'item',
          //   url: '/maintenance/extra-diesel/show-diesel'
          // }
        ]
      },
      {
        id: 'reports',
        title: 'Reports',
        type: 'collapse',
        icon: 'feather icon-menu',
        children: [
          
            
              {
                id: 'hpcl-full-report',
                title: 'HPCL Full Report',
                type: 'item',
                url: '/all-reports/hpcl-reports/hpcl-full-report'
              },
              {
                id: 'hpcl-trip-report',
                title: 'HPCL Trip Report',
                type: 'item',
                url: '/all-reports/hpcl-reports/hpcl-trip-report'
              }
            
          ,
        
            
              {
                id: 'iocl-full-report',
                title: 'IOCL Full Report',
                type: 'item',
                url: '/all-reports/iocl-reports/iocl-full-report'
              },
              {
                id: 'iocl-trip-report',
                title: 'IOCL Trip Report',
                type: 'item',
                url: '/all-reports/iocl-reports/iocl-trip-report'
              },
          {
            id: 'report-local',
            title: 'Local',
            type: 'item',
            url: '/all-reports/local-report'
          },
              {
                id: 'maintenance-bill-report',
                title: 'Maintenance Bill Report',
                type: 'item',
                url: '/all-reports/maintenance-report/maintenance-bill-report'
              },
              // {
              //   id: 'extra-diesel-report',
              //   title: 'Extra Diesel Report',
              //   type: 'item',
              //   url: '/all-reports/maintenance-report/extra-diesel-report'
              // }
            
          
        ]
      }
    ]
  },

];

@Injectable()
export class NavigationItem {
  public get() {
    return NavigationItems;
  }
}
