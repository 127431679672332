import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { YearsService } from 'src/app/Service/year/years.service';
import { YearModel } from '../../navigation/nav-content/year-model';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from 'src/app/authentication/authentication-guard/auth.service';


@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {
  yearlabel = "";
  year_list: any[];
  years: YearModel[] = [];
  user: any;
  constructor(
    public yearService: YearsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.spinner.show();

    this.user = this.authService.getAuthFromLocalStorage();

    console.log(this.user);
    this.yearService.getAll().subscribe((data) => {
      if (data.success) {
        this.years = data.data;
        this.year_list = data.data;
      }
      this.getIsActiveYear();
      // console.log(this.years);
      this.setlabel();
    })
    this.spinner.show();

  }




  getIsActiveYear() {
    this.yearService.getActiveYear().subscribe((data) => {

      if (data.success) {
      var years= data.data;
       console.log(data.data);
        this.yearlabel = years[0].year_label;
        localStorage.setItem('yearid',years[0].id);
        localStorage.setItem('openingDate', years[0].opening_date);
        localStorage.setItem('closingDate', years[0].closing_date);
      }


    })


  }



  setYear(id: string, opening_date: string, closing_date: string) {
    // console.log(id);
    this.spinner.show();
    localStorage.clear();

    localStorage.setItem('yearid', id);
    localStorage.setItem('opening_date', opening_date);
    localStorage.setItem('closing_date', closing_date);

    console.log('opening_nav' + localStorage.getItem('opening_date'));
    console.log('closing_nav' + localStorage.getItem('closing_date'));
    this.setlabel();
    // this.router.navigate(['/']);
    this.spinner.hide();
  }

  setlabel() {
    this.spinner.show();
    const yid = localStorage.getItem('yearid');
    this.year_list.map(item => {
      if (yid == item.id) {
        this.yearlabel = item.year_label;
        localStorage.setItem('yearid', item.id);
        localStorage.setItem('opening_date', item.openingDate);
        localStorage.setItem('closing_date', item.closingDate);
      }
    })
    this.spinner.hide();
  }

  logout() {
    this.authService.logout();
  }
}
